import React, { useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import AsSeenOn from "../../assets/images/asseenon.webp";
import K1 from "../../assets/images/k1.webp";
import K2 from "../../assets/images/k2.webp";
import K3 from "../../assets/images/k3.webp";
import K4 from "../../assets/images/k4.webp";
import K5 from "../../assets/images/k5.webp";
import K6 from "../../assets/images/k6.webp";
import K7 from "../../assets/images/k7.webp";
import K8 from "../../assets/images/k8.webp";
import K9 from "../../assets/images/k9.webp";
import K10 from "../../assets/images/k10.webp";
import K11 from "../../assets/images/k11.webp";
import K12 from "../../assets/images/k12.webp";
import L1 from "../../assets/images/l1.webp";
import L2 from "../../assets/images/l2.webp";
import L3 from "../../assets/images/l3.webp";
import L4 from "../../assets/images/l4.webp";
import L5 from "../../assets/images/l5.webp";
import L6 from "../../assets/images/l6.webp";
import L7 from "../../assets/images/l7.webp";
import L8 from "../../assets/images/l8.webp";
import L9 from "../../assets/images/l9.webp";
import L10 from "../../assets/images/l10.webp";
import L11 from "../../assets/images/l11.webp";
import L12 from "../../assets/images/l12.webp";
import L13 from "../../assets/images/l13.webp";
import L14 from "../../assets/images/l14.webp";
import L15 from "../../assets/images/l15.webp";
import L16 from "../../assets/images/l16.webp";
import L17 from "../../assets/images/l17.webp";
import L18 from "../../assets/images/l18.webp";
import L19 from "../../assets/images/l19.webp";
import L20 from "../../assets/images/l20.webp";
import L21 from "../../assets/images/l21.webp";
import L22 from "../../assets/images/l22.webp";
import L23 from "../../assets/images/l23.webp";
import L24 from "../../assets/images/l24.webp";
import L25 from "../../assets/images/l25.webp";
import L26 from "../../assets/images/l26.webp";
import L27 from "../../assets/images/l27.webp";
import L28 from "../../assets/images/l28.webp";
import L29 from "../../assets/images/l29.webp";
import New1 from "../../assets/images/news1.webp";
import New2 from "../../assets/images/news2.webp";
import New3 from "../../assets/images/news3.webp";
import New4 from "../../assets/images/news4.webp";
import New5 from "../../assets/images/news5.webp";
import New6 from "../../assets/images/news6.webp";
import Emp1 from "../../assets/images/emp-1.webp";
import Emp2 from "../../assets/images/emp-2.webp";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";

export const AboutContent = () => {
  const ourPhilosophy = [
    {
      image: K1,
      heading: "Add Value",
    },
    {
      image: K2,
      heading: "Keep it Simple",
    },
    {
      image: K3,
      heading: "Think like a Customer",
    },
    {
      image: K4,
      heading: "Trust People",
    },
    {
      image: K5,
      heading:
        "It’s okay to make mistakes but don’t repeat the same mistakes again",
    },
    {
      image: K6,
      heading: "Collaborate like an Army and Believe in Teamwork",
    },
    {
      image: K7,
      heading: "Take Pride for doing a Great job",
    },
    {
      image: K8,
      heading: "Basics First and Specifics Later",
    },
    {
      image: K9,
      heading: "Be Prepared for Continuous Learning",
    },
    {
      image: K10,
      heading: "Think on Paper",
    },
    {
      image: K11,
      heading: "Clients are Human, Understand their Emotions.",
    },
    {
      image: K12,
      heading: "Don’t assume, just Ask.",
    },
  ];
  const location = useLocation();
  const navigation = useNavigate();
  useEffect(() => {
    const { search } = location;
    const containerId = search.substring(4); // Remove the leading slash
    const element = document.getElementById(containerId);

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "center",
      });
    }
  }, [location]);
  return (
    <div>
      <div className=" pt-5 section-gap" id="purposeofsocialeagle">
        <div className="container">
          <div className="row justify-content-center ">
            <div className="col-lg-5 mb-lg-0  my-auto">
              <div>
                <h2 className="section-heading fs-3 lh-base  mb-3">
                  Purpose of{" "}
                  <span className="color-sec-head">Social Eagle</span>
                </h2>
                <h6 className=" lh-base  mb-4">
                  At our core, we strive to enrich lives by bringing unique
                  value.
                </h6>
                <h6 className=" lh-base mb-4 ">
                  Our aim is to elevate businesses by expanding their reach,
                  delivering valuable connections, and improving revenue
                  streams.
                </h6>
                <h6 className=" lh-base ">
                  We're dedicated to ensuring that business owners find joy and
                  success in their business, creating a real impact in the lives
                  the both of us touch.
                </h6>
              </div>
            </div>
            <div className="col-lg-6 my-auto">
              <h2 className="section-heading  center-aligned-head  fs-3 lh-base  mb-3 mt-4">
                “On <span className="text-lowercase font-bold"> a </span>{" "}
                Mission <span className="font-bold text-lowercase">to</span>{" "}
                <span className="color-sec-head">Add value</span>{" "}
                <span className="text-lowercase font-bold"> and </span> Make a
                Difference{" "}
                <span className="text-lowercase font-bold"> in </span>
                <span className="color-sec-head">People’s Lives”</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="data-play-head-bg">
        <div className="container">
          <div className="">
            <h4 className="text-white font-bold text-center py-4 text-capitalize">
              Greater Reach + Quality Leads + Increased Sales = Happy Business
              Owner
            </h4>
          </div>
        </div>
      </div>
      <div className="why-join-us ">
        <div className="container px-lg-5 px-3 our-clients-inner-sec pb-0 ">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <h2 className="section-heading text-center ">
                Our {" "}
                <span className="color-sec-head">  Philosophy</span>
              </h2>
            </div>
          </div>
          <div className="mb-3" id="ourphilosophy">
            <OwlCarousel
              className="owl-theme"
              loop
              autoplay={true}
              items="4"
              dots={true}
              smartSpeed={2000}
              nav={false}
              margin={20}
              // center={true}
              autoplayTimeout={5000}
              responsive={{
                360: {
                  items: "1",
                },
                414: {
                  items: "1",
                },
                670: {
                  items: "3",
                },
                992: {
                  items: "3",
                },
                1200: {
                  items: "4",
                },
              }}
            >
              {ourPhilosophy.map((item, index) => {
                return (
                  <div
                    className="case-study-card philosophy-card-height"
                    key={index}
                  >
                    <div>
                      <div className="">
                        <img className="op-img" src={item.image} alt=""></img>
                      </div>
                      <div className="text-center  pt-3">
                        <h6 className="lh-base mb-0">{item.heading}</h6>
                      </div>
                    </div>
                  </div>
                );
              })}
            </OwlCarousel>
          </div>
          <div

            className="row mt-5 justify-content-center"
          >
            <div className="col-lg-10">
              <h2 className="section-heading fs-2 text-center lh-base  mb-4">
                <span className="color-sec-head">
                  Innovation | Creativity | Self-Confidence
                </span>
              </h2>
            </div>
            <div className="col-lg-8">
              <div className="text-justify">
                <div>
                  <h6 className="lh-base mb-4">
                    These aren't just words; they're the fuel behind our
                    success. <br /> At{" "}
                    <span className="color-sec-head">Social Eagle</span>, we're
                    on a mission to transform Ambition into Achievement.
                  </h6>
                  <h6 className="lh-base mb-4">
                    Dreams become reality through Determination, Leadership, and
                    a Competitive Spirit. <br /> We don't settle for less than
                    first place.
                  </h6>{" "}
                  <h6 className="lh-base mb-4">
                    Our pioneering digital transformation embraces fresh
                    methodologies, ensuring excellence isn't an option; it's our
                    standard.
                  </h6>
                  <h6 className="lh-base color-sec-head mb-4">
                    Challenges? <br /> They trigger miracles.{" "}
                  </h6>
                  <h6 className="lh-base mb-4">
                    We thrive in the age of Creative Minds, where change leads
                    to opportunity.
                  </h6>
                  <h6 className="lh-base mb-4">
                    We're committed to ethical practices, ensuring our work
                    reflects our values and principles.
                  </h6>
                  <h6 className="lh-base mb-4">
                    We thrive on Innovation and Creativity with an eye for
                    Excellence.
                  </h6>
                  <h6 className="lh-base mb-4">
                    Through transformative Leadership, we positively contribute
                    to Faith and Trust, Hope and Competitiveness.
                  </h6>
                  <h6 className="lh-base mb-4">
                    In our <span className="color-sec-head"> #EagleSpirit</span>
                    , we achieve our Ambitious Goals through a Scientific
                    approach through Technology by taking Initiatives and Daily
                    Action.
                  </h6>
                  <h6 className="lh-base ">
                    Join the Journey towards Excellence. Let's raise the
                    standards together.
                  </h6>
                </div>
                <button
                  onClick={() => {
                    navigation("/ContactUs");
                  }}
                  type="button"
                  className="btn mt-4 mb-5  btn-primary contact-btn"
                >
                  Fly with us
                </button>
              </div>
            </div>
          </div>
          <div className="mb-5" id="meetourteam">
            <div className="text-center">
              <h2 className="section-heading fs-1 lh-base  mb-5">
                Meet <span className="text-lowercase font-bold">{" "}our{" "}</span>  <span className="color-sec-head">Team</span>
              </h2>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-12">
                {/* <div className="row justify-content-between"> */}
                <div className="row">
                  <div className="col-lg-3 col-md-6 mb-3">
                    <div className="case-study-card">
                      <img
                        className="w-100 img-top-team h-auto rounded-top"
                        src={L1}
                        alt=""
                      ></img>
                      <div className="text-center pt-3">
                        <h6 className="font-bold mb-2">Dharaneetharan G D</h6>
                        <p className="mb-0 small text-secondary team-para-he">
                          Founder & <br /> Thought Leader
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6  mb-3">
                    <div className="case-study-card">
                      <img
                        className="w-100 h-auto img-top-team rounded-top"
                        src={L2}
                        alt=""
                      ></img>
                      <div className="text-center pt-3">
                        <h6 className="font-bold mb-2">Vishnu Hari</h6>
                        <p className="mb-0 small text-secondary team-para-he">
                          Performance Marketer & <br /> Expert Trainer
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6  mb-3">
                    <div className="case-study-card">
                      <img
                        className="w-100 h-auto img-top-team rounded-top"
                        src={L3}
                        alt=""
                      ></img>
                      <div className="text-center pt-3">
                        <h6 className="font-bold mb-2">Sathish Sinraj</h6>
                        <p className="mb-0 small text-secondary team-para-he">
                          Sales & <br /> Acquisition Expert
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6  mb-3">
                    <div className="case-study-card">
                      <img
                        className="w-100 h-auto img-top-team rounded-top"
                        src={L4}
                        alt=""
                      ></img>
                      <div className="text-center pt-3">
                        <h6 className="font-bold mb-2">Priya Dharaneetharan</h6>
                        <p className="mb-0 small text-secondary team-para-he">
                          Personal Branding & <br /> Authority Creator
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6  mb-3">
                    <div className="case-study-card">
                      <img
                        className="w-100 h-auto img-top-team rounded-top"
                        src={L18}
                        alt=""
                      ></img>
                      <div className="text-center pt-3">
                        <h6 className="font-bold mb-2">Varsha D</h6>
                        <p className="mb-0 small text-secondary team-para-he">
                          Personal Branding & <br /> Authority Creator
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6  mb-3">
                    <div className="case-study-card">
                      <img
                        className="w-100 h-auto img-top-team rounded-top"
                        src={L6}
                        alt=""
                      ></img>
                      <div className="text-center pt-3">
                        <h6 className="font-bold mb-2">Manikandan</h6>
                        <p className="mb-0 small text-secondary team-para-he">
                          Creative Head
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6  mb-3">
                    <div className="case-study-card">
                      <img
                        className="w-100 h-auto img-top-team rounded-top"
                        src={L5}
                        alt=""
                      ></img>
                      <div className="text-center pt-3">
                        <h6 className="font-bold mb-2">Shatheeshram G</h6>
                        <p className="mb-0 small text-secondary team-para-he">
                          Sales Beast
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6  mb-3">
                    <div className="case-study-card">
                      <img
                        className="w-100 h-auto img-top-team rounded-top"
                        src={L20}
                        alt=""
                      ></img>
                      <div className="text-center pt-3">
                        <h6 className="font-bold mb-2">Aswin Kumar Ramesh</h6>
                        <p className="mb-0 small text-secondary team-para-he">
                          Executive - Admin & Operations
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6  mb-3">
                    <div className="case-study-card">
                      <img
                        className="w-100 h-auto img-top-team rounded-top"
                        src={L16}
                        alt=""
                      ></img>
                      <div className="text-center pt-3">
                        <h6 className="font-bold mb-2">Azhagarasan</h6>
                        <p className="mb-0 small text-secondary team-para-he">
                          Senior Video Editor
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6  mb-3">
                    <div className="case-study-card">
                      <img
                        className="w-100 h-auto img-top-team rounded-top"
                        src={L13}
                        alt=""
                      ></img>
                      <div className="text-center pt-3">
                        <h6 className="font-bold mb-2">Manoj</h6>
                        <p className="mb-0 small text-secondary team-para-he">
                          Executive Video Editor
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6  mb-3">
                    <div className="case-study-card">
                      <img
                        className="w-100 h-auto img-top-team rounded-top"
                        src={L15}
                        alt=""
                      ></img>
                      <div className="text-center pt-3">
                        <h6 className="font-bold mb-2">Praveen Kumar </h6>
                        <p className="mb-0 small text-secondary team-para-he">
                          Executive Video Editor
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6  mb-3">
                    <div className="case-study-card">
                      <img
                        className="w-100 h-auto img-top-team rounded-top"
                        src={L9}
                        alt=""
                      ></img>
                      <div className="text-center pt-3">
                        <h6 className="font-bold mb-2">Ganesh Kumar M</h6>
                        <p className="mb-0 small text-secondary team-para-he">
                          Sales Executive
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6  mb-3">
                    <div className="case-study-card">
                      <img
                        className="w-100 h-auto img-top-team rounded-top"
                        src={L17}
                        alt=""
                      ></img>
                      <div className="text-center pt-3">
                        <h6 className="font-bold mb-2">Anbarasan</h6>
                        <p className="mb-0 small text-secondary team-para-he">
                          Sales Executive
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6  mb-3">
                    <div className="case-study-card">
                      <img
                        className="w-100 h-auto img-top-team rounded-top"
                        src={L19}
                        alt=""
                      ></img>
                      <div className="text-center pt-3">
                        <h6 className="font-bold mb-2">Naresh</h6>
                        <p className="mb-0 small text-secondary team-para-he">
                          Sales Executive
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6  mb-3">
                    <div className="case-study-card">
                      <img
                        className="w-100 h-auto img-top-team rounded-top"
                        src={L22}
                        alt=""
                      ></img>
                      <div className="text-center pt-3">
                        <h6 className="font-bold mb-2">Pradeep</h6>
                        <p className="mb-0 small text-secondary team-para-he">
                          Sales Executive
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6  mb-3">
                    <div className="case-study-card">
                      <img
                        className="w-100 h-auto img-top-team rounded-top"
                        src={L21}
                        alt=""
                      ></img>
                      <div className="text-center pt-3">
                        <h6 className="font-bold mb-2">Ashwin</h6>
                        <p className="mb-0 small text-secondary team-para-he">
                          Sales Executive
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6  mb-3">
                    <div className="case-study-card">
                      <img
                        className="w-100 h-auto img-top-team rounded-top"
                        src={L23}
                        alt=""
                      ></img>
                      <div className="text-center pt-3">
                        <h6 className="font-bold mb-2">Karthikeyan</h6>
                        <p className="mb-0 small text-secondary team-para-he">
                          Performance Marketing Executive
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6  mb-3">
                    <div className="case-study-card">
                      <img
                        className="w-100 h-auto img-top-team rounded-top"
                        src={L24}
                        alt=""
                      ></img>
                      <div className="text-center pt-3">
                        <h6 className="font-bold mb-2">Gowtham </h6>
                        <p className="mb-0 small text-secondary team-para-he">
                          Performance Marketing Executive
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6  mb-3">
                    <div className="case-study-card">
                      <img
                        className="w-100 h-auto img-top-team rounded-top"
                        src={L25}
                        alt=""
                      ></img>
                      <div className="text-center pt-3">
                        <h6 className="font-bold mb-2">Surya Balasubramaniyan</h6>
                        <p className="mb-0 small text-secondary team-para-he">
                          Sales Executive
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6  mb-3">
                    <div className="case-study-card">
                      <img
                        className="w-100 h-auto img-top-team rounded-top"
                        src={L26}
                        alt=""
                      ></img>
                      <div className="text-center pt-3">
                        <h6 className="font-bold mb-2">Nithya </h6>
                        <p className="mb-0 small text-secondary team-para-he">
                          Sales Executive
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6  mb-3">
                    <div className="case-study-card">
                      <img
                        className="w-100 h-auto img-top-team rounded-top"
                        src={L27}
                        alt=""
                      ></img>
                      <div className="text-center pt-3">
                        <h6 className="font-bold mb-2">Sahana J</h6>
                        <p className="mb-0 small text-secondary team-para-he">
                          Accountant
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6  mb-3">
                    <div className="case-study-card">
                      <img
                        className="w-100 h-auto img-top-team rounded-top"
                        src={L28}
                        alt=""
                      ></img>
                      <div className="text-center pt-3">
                        <h6 className="font-bold mb-2">Vishva Venkataraman</h6>
                        <p className="mb-0 small text-secondary team-para-he">
                          Sales Executive
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6  mb-3">
                    <div className="case-study-card">
                      <img
                        className="w-100 h-auto img-top-team rounded-top"
                        src={L29}
                        alt=""
                      ></img>
                      <div className="text-center pt-3">
                        <h6 className="font-bold mb-2">Sujitha R</h6>
                        <p className="mb-0 small text-secondary team-para-he">
                          Senior Graphic Designer
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="headlinesandhighlights" className="container-fluid">
        <div className="mb-5">
          <div className="text-center">
            <h2 className="section-heading fs-1 lh-base  mb-3">
              <span className="color-sec-head">Headlines & Highlights</span>
            </h2>
            <h4 className="section-heading fs-4 lh-base  mb-3">
              Our Journey Through Media Interviews and Highlights!
            </h4>
          </div>
          <div className="">
            <div className="position-relative">
              <hr className="asseen-border" />
              <div className="asseenon-content ">
                <h4 className="color-sec-head fst-italic bg-white px-3 mb-0 ">As Seen on</h4>
              </div>
            </div>
            <div className="container-fluid">
              <img className="w-100 h-auto" src={AsSeenOn} alt=""></img>
            </div>
            <hr className="asseen-border" />
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-3">
              <a
                href="https://youtu.be/Mngfnhc84Eg?si=wSZrCOiU9hgj8CIn"
                target="_blank"
              >
                <div className="case-study-card mb-3 p-0">
                  <img className="w-100 h-auto rounded" src={New1} alt=""></img>
                </div>
              </a>
            </div>
            <div className="col-lg-3 col-md-3">
              <a
                href="https://youtu.be/BmJuACkCkMU?si=ngd9ujr1YUcizJbW"
                target="_blank"
              >
                <div className="case-study-card mb-3 p-0">
                  <img className="w-100 h-auto rounded" src={New3} alt=""></img>
                </div>
              </a>
            </div>
            <div className="col-lg-3 col-md-3">
              <a
                href="https://youtu.be/HkMSo5HOMJI?si=knvrDpfed62EZl66"
                target="_blank"
              >
                <div className="case-study-card mb-3 p-0">
                  <img className="w-100 h-auto rounded" src={New4} alt=""></img>
                </div>
              </a>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-3">
              <a
                href="https://youtu.be/UeGsCsj-PMA?si=9DkeD3p-fY19yNyv"
                target="_blank"
              >
                <div className="case-study-card mb-3 p-0">
                  <img className="w-100 h-auto rounded" src={New2} alt=""></img>
                </div>
              </a>
            </div>
            <div className="col-lg-3 col-md-3">
              <a
                href="https://youtu.be/BmJuACkCkMU?si=tFDMeJvujKDps3ni"
                target="_blank"
              >
                <div className="case-study-card mb-3 p-0">
                  <img className="w-100 h-auto rounded" src={New6} alt=""></img>
                </div>
              </a>
            </div>
            <div className="col-lg-3 col-md-3">
              <a
                href="https://youtu.be/mZ2T-ViI8ck?si=qtAZCS05x7ZdGWgu"
                target="_blank"
              >
                <div className="case-study-card mb-3 p-0">
                  <img className="w-100 h-auto rounded" src={New5} alt=""></img>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div id="empoweringchange" className="container">
        <div className="mb-5">
          <div className="text-center">
            <h2 className="section-heading fs-1 lh-base  mb-3">
              Empowering <span className="color-sec-head">Change</span>
            </h2>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6">
              <div className="mb-3 p-0">
                <img
                  className="w-100 h-auto rounded-top"
                  src={Emp2}
                  alt=""
                ></img>
                <div className="px-lg-5 p-0">
                  <h5 className="font-bold mb-3">
                    Our Impactful Social Responsibility Initiatives!
                  </h5>
                  <p className="text-secondary mb-2">
                    At Social Eagle, we partnered with Anandham Youth Foundation
                    to offer free monthly skill development classes for
                    students.
                  </p>
                  <p className="text-secondary mb-2">
                    We're dedicated to helping young talents learn and grow
                    without any barriers.
                  </p>
                  <p className="text-secondary mb-2">
                    Our goal is simple: empower students with the skills they
                    need to thrive.
                  </p>
                  <p className="text-secondary mb-2">
                    Together, we're making education more inclusive and
                    accessible.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6  col-md-6 my-auto">
              <div className="mb-3 p-0">
                <img
                  className="w-100 h-auto rounded-top"
                  src={Emp1}
                  alt=""
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
